import React from 'react'
import type { MovieFieldsLightFragment, SeriesFieldsLightFragment } from '@nordic-web/gql'
import { TypographyText } from '@nordic-web/ui-components'
import {
  CardButtonContainer,
  CardContainer,
  CardFooterContainer,
  CardImageContainer,
  CardLabelContainer,
  CardMetaOverlayContainer,
  CardMobileMenuToggle,
} from '@nordic-web/ui-components'
import { isUpsellMedia } from '@nordic-web/utils/misc/is-upsell-media'
import { CardVideoTrailer } from '@/components/cards/card-video-trailer'
import type { MobileCardActionsClick, OnCardClick } from '@/components/cards/types'
import { FadeInImage } from '@/components/fade-in-image'
import { HorizontalAutoScroll } from '@/components/horizontal-auto-scroll/horizontal-auto-scroll'
import { LabelFactory } from '@/components/label-factory'
import { StandardMetaPropertyList } from '@/components/meta-property-list/standard-meta-property-list'
import { usePrefetchCdpQuery } from '@/components/prefetch-links/cdp-prefetch-link'
import { MuteTrailersButton } from '@/components/trailer/mute-trailers-button'
import { FavoriteButton } from '@/features/favorite/favorite-button'
import { paths } from '@/helpers/paths'
import { useIsAboveTablet } from '@/hooks/use-breakpoint'
import { useHovered } from '@/hooks/use-hovered'
import { AssetTracking } from '@/tracking/asset-tracking'
import { detectTouch } from '@/utils/detect-touch'

type ProgramCardProps = {
  onCardClick?: OnCardClick
  onMobileActionsClick?: MobileCardActionsClick
  program: MediaPanelItem
  showOnlyUpsellLabel?: boolean
}

export type MediaPanelItem = MovieFieldsLightFragment | SeriesFieldsLightFragment

export const ProgramCard = ({ onCardClick, onMobileActionsClick, program, showOnlyUpsellLabel }: ProgramCardProps) => {
  const { __typename, id, slug, title, images } = program

  // NOTE(hover-trailer): We wish to play the trailer with a 1 second delay on card hover. To avoid performance regression,
  // we wait 500ms until we render the video element and start the loading of the video. Then we make the video element
  // wait 500ms before playing the trailer.
  const isLargeScreen = useIsAboveTablet()
  const { onMouseLeave, onMouseEnter, isHovered } = useHovered({ delay: 500, enable: isLargeScreen && !detectTouch() })

  const handleClick = () => {
    if (!onCardClick) return

    onCardClick(program)
  }

  const hasTrailer = program?.trailers?.mp4 || program?.trailers?.webm
  const isUpsell = isUpsellMedia(program)

  const prefetch = usePrefetchCdpQuery(id)

  return (
    <AssetTracking
      content_media_id={program.id}
      upsell={isUpsell ? { item: 'card', package: program.upsell?.tierPackageLink.packageId } : null}
    >
      {({ trackOnAssetClick }) => (
        <CardContainer
          aria-label={title}
          data-testid="program"
          href={paths.program.urlString({ id, slug })}
          onClick={() => {
            trackOnAssetClick()
            handleClick()
          }}
          onMouseEnter={() => {
            onMouseEnter()
            prefetch()
          }}
          onMouseLeave={onMouseLeave}
        >
          <CardImageContainer>
            <FadeInImage
              alt={title}
              source={images.main16x9Annotated.sourceEncoded}
              backgroundColorHex={program?.images?.cover2x3.meta?.muteBgColor?.hex}
            />
            <CardLabelContainer>
              <LabelFactory media={program} nwPlacement="corner" showOnlyUpsellLabel={showOnlyUpsellLabel} />
            </CardLabelContainer>
            <CardButtonContainer>
              <FavoriteButton mediaType={__typename} mediaId={id} />
              {isHovered && hasTrailer && <MuteTrailersButton />}
            </CardButtonContainer>
            {isHovered && hasTrailer && (
              <CardVideoTrailer
                playDelay={500} // See NOTE(hover-trailer).
                video16x9mp4={program.trailers?.mp4}
                video16x9webm={program.trailers?.webm}
              />
            )}
            <CardMetaOverlayContainer nwBackgroundColor={program?.images?.cover2x3.meta?.muteBgColor?.hex}>
              <HorizontalAutoScroll active={isHovered}>
                <TypographyText nwVariant="body4Strong" nwColor="primary">
                  {program.title}
                </TypographyText>
                <StandardMetaPropertyList compact {...program} oneLine />
              </HorizontalAutoScroll>
            </CardMetaOverlayContainer>
          </CardImageContainer>

          {onMobileActionsClick && (
            <CardFooterContainer>
              <CardMobileMenuToggle nwOnClick={() => onMobileActionsClick(program)} />
            </CardFooterContainer>
          )}
        </CardContainer>
      )}
    </AssetTracking>
  )
}
