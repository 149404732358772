import React from 'react'
import type { EpisodeFieldsFragment } from '@nordic-web/gql'
import {
  CardContainer,
  CardFooterContainer,
  CardFooterTextContainer,
  CardImageContainer,
  CardLabelContainer,
  CardMainText,
  CardSecondaryText,
  Label,
} from '@nordic-web/ui-components'
import { isUpsellMedia } from '@nordic-web/utils/misc/is-upsell-media'
import type { OnCardClick } from '@/components/cards/types'
import { FadeInImage } from '@/components/fade-in-image'
import { LabelFactory } from '@/components/label-factory'
import { usePrefetchVideoQuery } from '@/components/prefetch-links/video-prefetch-link'
import { paths } from '@/helpers/paths'
import { AssetTracking } from '@/tracking/asset-tracking'

type EpisodeCardProps = {
  videoAsset: EpisodeFieldsFragment
  onCardClick?: OnCardClick
  labelText?: string | null
}

export const EpisodeCard = ({ videoAsset, onCardClick, labelText }: EpisodeCardProps) => {
  const href = paths.video.urlString({ assetId: videoAsset.id, slug: videoAsset.slug })

  const handleClick = () => {
    if (onCardClick) {
      onCardClick(videoAsset)
    }
  }

  const isUpsell = isUpsellMedia(videoAsset)

  const prefetch = usePrefetchVideoQuery(videoAsset.id)

  return (
    <AssetTracking
      content_media_id={videoAsset.id}
      upsell={isUpsell ? { item: 'card', package: videoAsset.upsell?.tierPackageLink.packageId } : null}
    >
      {({ trackOnAssetClick }) => (
        <CardContainer
          href={href}
          onMouseOver={prefetch}
          onClick={() => {
            handleClick()
            trackOnAssetClick()
          }}
        >
          <CardImageContainer>
            <FadeInImage alt={videoAsset.title} source={videoAsset.images.main16x9.sourceEncoded} />
            <CardLabelContainer>
              {labelText ? (
                <Label nwPlacement="corner" nwVariant="announcement">
                  {labelText}
                </Label>
              ) : (
                <LabelFactory media={videoAsset} nwPlacement="corner" />
              )}
            </CardLabelContainer>
          </CardImageContainer>

          <CardFooterContainer>
            <CardFooterTextContainer>
              <CardMainText>{videoAsset.title}</CardMainText>
              {videoAsset.series?.title && <CardSecondaryText>{videoAsset.series.title}</CardSecondaryText>}
            </CardFooterTextContainer>
          </CardFooterContainer>
        </CardContainer>
      )}
    </AssetTracking>
  )
}
