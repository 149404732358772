import type { ReactNode } from 'react'
import React, { useRef } from 'react'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import styled, { useTheme } from 'styled-components'
import type { ImageFieldsLightFragment, PackageTierLinkFieldsFragment, ThemePanelFieldsFragment } from '@nordic-web/gql'
import { Button, type IconVariant, Label, ResponsiveImage, Stack, TypographyText } from '@nordic-web/ui-components'
import { desktop } from '@nordic-web/ui-styles'
import { useIsClientSide } from '@nordic-web/utils/hooks/use-is-client-side'
import type { TrailerVideoController } from '@/components/background-trailer'
import { BackgroundTrailer } from '@/components/background-trailer'
import { BackgroundTrailerControls } from '@/components/background-trailer/background-trailer-controls'
import { LabelFactory } from '@/components/label-factory'
import { MaxWidthContainerLg } from '@/components/max-width-container/max-width-container-lg'
import { StandardMetaPropertyList } from '@/components/meta-property-list/standard-meta-property-list'
import { BulletList } from '@/components/theme-panel/bullet-list'
import { ThemePanelCampaignImage } from '@/components/theme-panel/campaign-image'
import { PriceTexts } from '@/features/packages/components/package-price'
import { paths } from '@/helpers/paths'
import { AssetTracking } from '@/tracking/asset-tracking'
import { formatPrice } from '@/utils/price'

type ParseLinkResult = {
  icon?: IconVariant
  href?: string | null
  metadata?: ReactNode
  label?: ReactNode
  trackingId?: string
  brandLogo?: ImageFieldsLightFragment | null
  upsell?: {
    tierPackageLink: PackageTierLinkFieldsFragment
    labelText: string
  } | null
}

const parseLink = (link?: ThemePanelFieldsFragment['link']): ParseLinkResult => {
  switch (link?.__typename) {
    case 'ThemePanelClipLink':
      return {
        href: paths.clips.urlString({ assetId: link.clip.id, slug: link.clip.slug }),
        icon: 'player-play-filled',
        trackingId: link.clip.id,
      }
    case 'ThemePanelEpisodeLink':
      return {
        href: paths.video.urlString({ assetId: link.episode.id, slug: link.episode.slug }),
        icon: 'player-play-filled',
        trackingId: link.episode.id,
        upsell: link.episode.upsell,
        label: <LabelFactory media={link.episode} />,
      }
    case 'ThemePanelMovieLink':
      return {
        href: paths.program.urlString({ id: link.movie.id, slug: link.movie.slug }),
        metadata: <StandardMetaPropertyList {...link.movie} />,
        trackingId: link.movie.id,
        brandLogo: link.movie.images.brandLogo ?? null,
        upsell: link.movie.upsell,
        label: <LabelFactory media={link.movie} />,
      }
    case 'ThemePanelPageLink':
      return {
        href: paths.page.urlString({ id: link.page.id }),
        trackingId: link.page.id,
        label: link.upsellLabelText ? <Label nwVariant="plus">{link.upsellLabelText}</Label> : null,
      }
    case 'ThemePanelSeriesLink':
      return {
        href: paths.program.urlString({ id: link.series.id, slug: link.series.slug }),
        metadata: (
          <StandardMetaPropertyList
            numberOfAvailableSeasons={link.series.numberOfAvailableSeasons}
            genres={link.series.genres}
            parentalRating={link.series.parentalRating}
          />
        ),
        trackingId: link.series.id,
        brandLogo: link.series.images.brandLogo ?? null,
        upsell: link.series.upsell,
        label: <LabelFactory media={link.series} />,
      }
    case 'ThemePanelUrlsLink':
      return {
        href: link.webUrl,
        label: link.upsellLabelText ? <Label nwVariant="plus">{link.upsellLabelText}</Label> : null,
      }
    case 'ThemePanelSportEventLink':
      return {
        href: paths.program.urlString({ id: link.sportEvent.id, slug: link.sportEvent.slug }),
        trackingId: link.sportEvent.id,
        brandLogo: link.sportEvent.images.brandLogo ?? null,
        upsell: link.sportEvent.upsell,
        metadata: (
          <StandardMetaPropertyList
            arena={link.sportEvent.arena}
            league={link.sportEvent.league}
            round={link.sportEvent.round}
            broadcastTime={link.sportEvent.playableFrom?.readableDate}
          />
        ),
        label: <LabelFactory media={link.sportEvent} />,
      }
    default:
      return {}
  }
}

type ThemePanelProps = {
  panel: ThemePanelFieldsFragment
}

export const ThemePanel = ({ panel }: ThemePanelProps) => {
  const {
    title,
    subtitle,
    pitch,
    themePanelLinkText,
    hexColor,
    images,
    link,
    trailers,
    showMetadataForLink: shouldShowMetadataForLink,
    disclaimer,
    bullets,
    campaignDetails,
    tierPrice,
  } = panel
  const theme = useTheme()
  const t = useTranslations()
  const videoRef = useRef<TrailerVideoController | null>(null)
  const parsedLink = parseLink(link)
  const color = hexColor || images?.image16x9?.meta?.muteBgColor?.hex || theme.color.base.background

  const hasTrailers = trailers?.mp4 || trailers?.webm
  const isClientSide = useIsClientSide()
  const brandLogo = parsedLink.brandLogo
  const isUpsell = !!parsedLink?.upsell

  const campaignImage = campaignDetails?.campaignLabelImage?.sourceEncoded
  const campaignPrice = tierPrice?.campaignDetails

  return (
    <AssetTracking content_media_id={parsedLink.trackingId ?? ''} upsell={isUpsell ? { item: 'theme_panel' } : null}>
      {({ trackOnAssetClick }) => (
        <OuterStack nwGap={4}>
          {campaignImage && <ThemePanelCampaignImage width={300} height={300} alt="" src={campaignImage} />}
          <Container color={color}>
            <ContentSection>
              <ContentWrapper>
                <Stack nwGap={3} nwJustifyContent="space-between" nwAlignItems="flex-start" nwFlex={1}>
                  <div>{parsedLink.label}</div>
                  <Stack nwGap={3}>
                    {brandLogo?.sourceEncoded && (
                      <div>
                        <ResponsiveImage height={24} width={50} src={brandLogo.sourceEncoded} alt="" />
                      </div>
                    )}
                    <TypographyText nwVariant="body3">{subtitle}</TypographyText>
                    <MaxWidthContainerLg>
                      <TypographyText nwVariant="title2">{title}</TypographyText>
                    </MaxWidthContainerLg>
                    {shouldShowMetadataForLink && parsedLink.metadata && <>{parsedLink.metadata}</>}
                    {pitch && (
                      <MaxWidthContainerLg>
                        <TypographyText nwVariant="body3">{pitch}</TypographyText>
                      </MaxWidthContainerLg>
                    )}
                    <BulletList bullets={bullets} />
                    {campaignPrice && (
                      <div>
                        <PriceTexts
                          isLarge={false}
                          isStrikeThrough
                          nonHightlightedPrice={t('subscription__general__price', {
                            price: formatPrice(tierPrice.defaultPrice),
                          })}
                          highlightedPrice={t('subscription__trial__price_month', {
                            price: formatPrice(campaignPrice.price),
                            duration: campaignPrice.numberOfPeriods,
                          })}
                        />
                      </div>
                    )}
                    <Stack nwDirection="row">
                      {parsedLink.href && (
                        <Link href={parsedLink.href} legacyBehavior passHref>
                          <Button onClick={() => trackOnAssetClick()} nwIconLast={parsedLink.icon} as="a">
                            {themePanelLinkText}
                          </Button>
                        </Link>
                      )}
                      {isClientSide && hasTrailers && (
                        <BackgroundTrailerControls trailerVideoController={videoRef.current} />
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </ContentWrapper>
            </ContentSection>

            <ImageTrailerSection>
              <VideoWrapper>
                <Gradient color={color} />
                {images?.image16x9 && (
                  <BackgroundTrailer
                    color={color}
                    ref={videoRef}
                    desktopImage={images.image16x9.sourceEncoded}
                    mobileImage={images.image16x9.sourceEncoded}
                    trailers={trailers}
                  />
                )}
              </VideoWrapper>
            </ImageTrailerSection>
          </Container>
          {disclaimer && (
            <TypographyText nwVariant="smallText" nwColor="secondary">
              {disclaimer}
            </TypographyText>
          )}
        </OuterStack>
      )}
    </AssetTracking>
  )
}

const OuterStack = styled(Stack)({
  position: 'relative',
})

const Container = styled.div<{ color: string }>(({ color, theme }) => ({
  position: 'relative',
  backgroundColor: color,
  borderRadius: theme.radii.border_radius_large,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column-reverse',
  [desktop]: {
    flexDirection: 'row',
  },
}))

const ContentSection = styled.div({
  display: 'flex',
  zIndex: 2,
  flex: 4,
  marginTop: -80,
  [desktop]: {
    marginTop: 0,
  },
})

const ImageTrailerSection = styled.div({
  zIndex: 1,
  flex: 6,
})

const Gradient = styled.div<{ color: string }>(({ color }) => ({
  position: 'absolute',
  background: `linear-gradient(0deg, ${color} 0%, transparent 45%)`,
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: 1,
  [desktop]: {
    background: `linear-gradient(90deg, ${color} 0%, transparent 30%)`,
  },
}))

const VideoWrapper = styled.div({
  position: 'relative',
  aspectRatio: '16 / 9',
})

const ContentWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flex: 1,
  flexDirection: 'column',
  padding: theme.space(4),
  position: 'relative',
  [desktop]: {
    padding: theme.space(8),
  },
}))
